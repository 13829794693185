import { isFeatureEnabled } from '../../../../services/Features';
import { getDefaultRatingsReview } from '../../../FeedbackBouquet/constants/section-templates';
const standardRating = getDefaultRatingsReview();
const questionnaireAnnualReview = cycleType => [
    {
        id: 'graph',
        type: 'Graph',
        data: {
            enabled: true,
            requiredForManager: false,
            requiredForReviewee: false,
            xAxis: {
                low: 'poor',
                high: 'great',
                label: 'Behavior',
                description: "Behavior: Rate the reviewed person's behavior and attitude towards coworkers and clients. Consider helpfulness, positivity, vision, and further so called soft factors.",
            },
            yAxis: {
                low: 'poor',
                high: 'great',
                label: 'Results',
                description: 'Results: How did the reviewed person perform? Take into account her objectives, capabilities and seniority.',
            },
        },
    },
    {
        id: '1',
        confidential: false,
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>How would you say {{ firstName }} has impacted the company in the last 6 months? Please provide at least 3 examples.</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Reflecting on what you achieved the last 6 months, how would you say you impacted the company? Please provide at least 3 examples.</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
    },
    {
        id: '2',
        confidential: false,
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>In your opinion and based on the discussions you have had with {{ firstName }}, what do you think {{ sheHe }} could focus on for the upcoming 6 months/what would help {{ herHim }} be more impactful?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>In your opinion and based on the feedback you have received lately, what would you like to focus on for the upcoming 6 months/what would help you to be more impactful?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Are there tools and resources that {{ firstName }} should use more often?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Do you have all the necessary tools and resources to do your job?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '17m00oe-0zsjr0q',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: "<!--MARKUP_VERSION:v3--><p>What are {{ firstName }}'s current responsibilities for this role? </p>",
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What are your current responsibilities for this role?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '00t6ihz-1qw4uzp',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Think about how your team has collaborated in the past year. What has {{ firstName }} brought to those collaborations?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Think about the times you’ve worked collaboratively in the past year. Do you think the collaborations were successful? If so, what made them a success? If not, what could be improved?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1glc950-1ee453f',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>How can {{ firstName }} contribute towards the goals for the company in the upcoming year?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What are your short-and long-term goals with the company, and for your career?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '0kmgury-017s95b',
    },
    ...(cycleType === 'SIMPLE' || !isFeatureEnabled('FEATURE_secondaryOverallRating')
        ? []
        : [
            {
                id: 'secondaryOverallRating',
                type: 'SecondaryOverallRating',
                data: {
                    enabled: true,
                    enabledForManager: true,
                    enabledForReviewee: false,
                    requiredForManager: false,
                    requiredForReviewee: false,
                    inEditMode: false,
                    ratings: [
                        {
                            code: '5',
                            label: '5 - Exceptional',
                            description: 'The employee achieved truly amazing results, this employee is a role model to others.',
                            value: 1,
                        },
                        {
                            code: '4',
                            label: '4 - Exceeds expectations',
                            description: 'The employee is doing an outstanding job and working well above what is expected from most people in this position and seniority level.',
                            value: 2,
                        },
                        {
                            code: '3',
                            label: '3 - Meets expectations',
                            description: 'The employee is doing a great job, and is occasionally exceeding expectations, while there is room for improvement.',
                            value: 3,
                        },
                        {
                            code: '2',
                            label: '2 - Improvement needed',
                            description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                            value: 4,
                        },
                        {
                            code: '1',
                            label: '1 - Unsatisfactory',
                            description: 'The employee is not meeting expectations for the role.',
                            value: 5,
                        },
                    ],
                },
            },
        ]),
    {
        id: 'overallRating',
        type: 'OverallRating',
        data: {
            confidential: false,
            enabled: true,
            enabledForManager: true,
            enabledForReviewee: true,
            requiredForManager: false,
            requiredForReviewee: false,
            inEditMode: false,
            ratings: [
                {
                    code: '5',
                    label: '5 - Exceptional',
                    description: 'The employee achieved truly amazing results, this employee is a role model to others.',
                    value: 1,
                },
                {
                    code: '4',
                    label: '4 - Exceeds expectations',
                    description: 'The employee is doing an outstanding job and working well above what is expected from most people in this position and seniority level.',
                    value: 2,
                },
                {
                    code: '3',
                    label: '3 - Meets expectations',
                    description: 'The employee is doing a great job, and is occasionally exceeding expectations, while there is room for improvement.',
                    value: 3,
                },
                {
                    code: '2',
                    label: '2 - Improvement needed',
                    description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                    value: 4,
                },
                {
                    code: '1',
                    label: '1 - Unsatisfactory',
                    description: 'The employee is not meeting expectations for the role.',
                    value: 5,
                },
            ],
        },
    },
];
const questionnaireObjectives = [
    {
        title: 'Reflection',
        description: '<!--MARKUP_VERSION:v3--><p>The following questions are related to outcomes and performance on previously set objectives.</p>',
        type: 'ReviewHeading',
        questionType: 'HEADING',
        manager: {},
        reviewee: {},
        id: '0skukje-0f8367l',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Which objectives did {{ firstName }} do well with?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Which objectives did you do well with?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1jlnc1q-0bgjqpv',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Which objectives did {{ firstName }} struggle with?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Which objectives did you struggle with?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '0qbfpll-1btu9k9',
    },
    {
        type: 'ReviewScale',
        questionType: 'SCALES',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Overall, do you think that the progress that {{ firstName }} made toward these objectives was successful?</p>',
            allowComment: true,
            scale: [
                {
                    id: '3',
                    text: 'Yes',
                },
                {
                    id: '4',
                    text: 'No',
                },
            ],
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Overall, do you think that the progress that you made toward these objectives was successful?</p>',
            allowComment: true,
            scale: [
                {
                    id: '3',
                    text: 'Yes',
                },
                {
                    id: '4',
                    text: 'No',
                },
            ],
        },
        id: '02yyvo6-04qs1ta',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Why do you feel that {{ firstName }} was or was not successful at meeting {{ herHis }} objectives?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Why do you feel that you were or were not successful at meeting your objectives?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '0lb0l39-0f43i3j',
    },
    {
        type: 'ReviewSlider',
        questionType: 'SLIDER',
        confidentialTopic: false,
        manager: {
            question: "<!--MARKUP_VERSION:v3--><p>On a scale of 1-5, how would you rate {{ firstName }}'s overall objective progress?</p>",
            allowComment: true,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>On a scale of 1-5, how would you rate your overall objective progress?</p>',
            allowComment: true,
        },
        sliderSteps: 5,
        sliderLabels: ['Unsuccessful', 'Successful'],
        id: '0fsydbc-01wqlta',
    },
    {
        title: 'Moving Forward',
        description: '<!--MARKUP_VERSION:v3--><p>The following questions relate to choosing and setting Objectives moving forward. </p>',
        type: 'ReviewHeading',
        questionType: 'HEADING',
        manager: {},
        reviewee: {},
        id: '0254pvz-1b0jc85',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Are there any objectives that you feel {{ firstName }} should continue working toward? If so, which?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Are there any objectives that you feel you should continue working toward? If so, which?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1vu7gq4-09hfqu8',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Are there any new objectives that you feel {{ firstName }} should set?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What new objectives do you want to set?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1z0k9de-05g3hy1',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: "<!--MARKUP_VERSION:v3--><p>How will {{ firstName }}'s objectives help {{ herHim }} succeed in {{ herHis }} role?</p>",
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>How will these objectives help you succeed in your role?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1rsdh43-0okscxq',
    },
];
const questionnaireProbation = cycleType => [
    {
        id: '1',
        confidential: false,
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Is {{ firstName }} performing as you’d expected {{ herHim }} to during the onboarding period?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>So far, is your role what you expected it to be?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
    },
    {
        id: '2',
        confidential: false,
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>What has {{ firstName }} picked up quickly? What areas still need work?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Do you feel like you’ve received access to all of the information, tools, and resources you need to complete your responsibilities? Are there any specific tools or training sessions that you think would help you be successful?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>Have you been given the support you’ve needed in onboarding {{ firstName }} ?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>Who or what has been most helpful during the onboarding process?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '1dvd5xq-116a2ve',
    },
    {
        type: 'ReviewQuestion',
        questionType: 'TEXT',
        confidentialTopic: false,
        manager: {
            question: '',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What would have been helpful to learn earlier in the training period?</p>',
            ratingsEnabled: false,
            ratings: standardRating,
        },
        id: '0s8e42r-1scwf36',
    },
    ...(cycleType === 'SIMPLE' || !isFeatureEnabled('FEATURE_secondaryOverallRating')
        ? []
        : [
            {
                id: 'secondaryOverallRating',
                type: 'SecondaryOverallRating',
                data: {
                    enabled: true,
                    enabledForManager: true,
                    enabledForReviewee: false,
                    requiredForManager: false,
                    requiredForReviewee: false,
                    inEditMode: false,
                    ratings: [
                        {
                            code: '5',
                            label: '5 - Exceptional',
                            description: 'The employee achieved truly amazing results, this employee is a role model to others.',
                            value: 1,
                        },
                        {
                            code: '4',
                            label: '4 - Exceeds expectations',
                            description: 'The employee is doing an outstanding job and working well above what is expected from most people in this position and seniority level.',
                            value: 2,
                        },
                        {
                            code: '3',
                            label: '3 - Meets expectations',
                            description: 'The employee is doing a great job, and is occasionally exceeding expectations, while there is room for improvement.',
                            value: 3,
                        },
                        {
                            code: '2',
                            label: '2 - Improvement needed',
                            description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                            value: 4,
                        },
                        {
                            code: '1',
                            label: '1 - Unsatisfactory',
                            description: 'The employee is not meeting expectations for the role.',
                            value: 5,
                        },
                    ],
                },
            },
        ]),
    {
        id: 'overallRating',
        type: 'OverallRating',
        data: {
            confidential: false,
            enabled: true,
            enabledForManager: true,
            enabledForReviewee: true,
            requiredForManager: false,
            requiredForReviewee: false,
            inEditMode: false,
            ratings: [
                {
                    code: '5',
                    label: '5 - Exceeds expectations by far',
                    description: 'The employee achieved truly amazing results.',
                    value: 1,
                },
                {
                    code: '4',
                    label: '4 - Exceeds expectations, outstanding job',
                    description: 'The employee is working well above what is expected from most people in this position, seniority, and salary band.',
                    value: 2,
                },
                {
                    code: '3',
                    label: '3 - Meets expectations, great job',
                    description: 'The employee is doing a great job, and is occasionally exceeding expectations, while occasionally there is room for improvement.',
                    value: 3,
                },
                {
                    code: '2',
                    label: '2 - Below expectations',
                    description: 'The employee is struggling to meet expectations. Sometimes the expectations are met, but there is a strong need for improvement.',
                    value: 4,
                },
                {
                    code: '1',
                    label: '1 - Insufficient',
                    description: 'The employee is not meeting expectations for the role. The situation needs to improve significantly.',
                    value: 5,
                },
            ],
        },
    },
];
const questionnaireCareerGrowth = [
    {
        confidentialTopic: false,
        manager: {
            question: '',
            ratings: standardRating,
            allowComment: false,
            ratingsEnabled: false,
            scale: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What are your long term career aspirations?</p>',
            ratings: standardRating,
            allowComment: false,
            ratingsEnabled: false,
            scale: standardRating,
        },
        title: '',
        description: '',
        sliderSteps: 0,
        sliderLabels: [],
        questionType: 'TEXT',
        type: 'ReviewQuestion',
        id: '0',
    },
    {
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>What are some strengths {{ firstName }} has that we are under-utilizing? </p>',
            ratings: standardRating,
            allowComment: false,
            ratingsEnabled: false,
            scale: standardRating,
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>What strengths do you have that you feel we are under-utilizing?</p>',
            ratings: standardRating,
            allowComment: false,
            ratingsEnabled: false,
            scale: standardRating,
        },
        title: '',
        description: '',
        sliderSteps: 0,
        sliderLabels: [],
        questionType: 'TEXT',
        type: 'ReviewQuestion',
        id: '1',
    },
    {
        confidentialTopic: false,
        manager: {
            question: '<!--MARKUP_VERSION:v3--><p>In the last six months, have you spoken to {{ firstName }} about {{ herHis }} career development?</p>',
            ratings: [
                {
                    text: 'Yes',
                    id: '0',
                },
                {
                    text: 'No',
                    id: '1',
                },
            ],
            allowComment: true,
            ratingsEnabled: false,
            scale: [
                {
                    text: 'Yes',
                    id: '0',
                },
                {
                    text: 'No',
                    id: '1',
                },
            ],
        },
        reviewee: {
            question: '<!--MARKUP_VERSION:v3--><p>In the last six months, has someone spoken to you about your career development?</p>',
            ratings: [
                {
                    text: 'Yes',
                    id: '0',
                },
                {
                    text: 'No',
                    id: '1',
                },
            ],
            allowComment: true,
            ratingsEnabled: false,
            scale: [
                {
                    text: 'Yes',
                    id: '0',
                },
                {
                    text: 'No',
                    id: '1',
                },
            ],
        },
        title: '',
        description: '',
        sliderSteps: 0,
        sliderLabels: [],
        questionType: 'SCALES',
        type: 'ReviewScale',
        id: '2',
    },
    {
        confidentialTopic: false,
        manager: {
            question: 'Which learning and development activities would help {{ firstName }} achieve {{ herHis }} goals and succeed in {{ herHis }} role?',
            ratings: [
                {
                    text: 'In-house training',
                    id: '0',
                },
                {
                    text: 'External training',
                    id: '1',
                },
                {
                    text: 'On-the-job coaching',
                    id: '2',
                },
                {
                    text: 'Mentorship from senior staff',
                    id: '3',
                },
                {
                    text: 'Exposure to another role',
                    id: '4',
                },
                {
                    text: 'Professional coaching/counselling',
                    id: '5',
                },
            ],
            allowComment: true,
            ratingsEnabled: true,
            scale: [
                {
                    text: 'In-house training',
                    id: '0',
                },
                {
                    text: 'External training',
                    id: '1',
                },
                {
                    text: 'On-the-job coaching',
                    id: '2',
                },
                {
                    text: 'Mentorship from senior staff',
                    id: '3',
                },
                {
                    text: 'Exposure to another role',
                    id: '4',
                },
                {
                    text: 'Professional coaching/counselling',
                    id: '5',
                },
            ],
        },
        reviewee: {
            question: 'Which learning and development activities would help you achieve your goals and succeed in your role?',
            ratings: [
                {
                    text: 'In-house training',
                    id: '0',
                },
                {
                    text: 'External training',
                    id: '1',
                },
                {
                    text: 'On-the-job coaching',
                    id: '2',
                },
                {
                    text: 'Mentorship from senior staff',
                    id: '3',
                },
                {
                    text: 'Exposure to another role',
                    id: '4',
                },
                {
                    text: 'Professional coaching/counselling',
                    id: '5',
                },
            ],
            allowComment: true,
            ratingsEnabled: true,
            scale: [
                {
                    text: 'In-house training',
                    id: '0',
                },
                {
                    text: 'External training',
                    id: '1',
                },
                {
                    text: 'On-the-job coaching',
                    id: '2',
                },
                {
                    text: 'Mentorship from senior staff',
                    id: '3',
                },
                {
                    text: 'Exposure to another role',
                    id: '4',
                },
                {
                    text: 'Professional coaching/counselling',
                    id: '5',
                },
            ],
        },
        title: '',
        description: '',
        sliderSteps: 0,
        sliderLabels: [],
        questionType: 'CHECKBOXES',
        type: 'ReviewCheckboxes',
        id: '3',
    },
];
export const templates = cycleType => [
    {
        id: '1',
        title: 'Annual Review',
        description: 'Provide feedback on employees’ strengths and areas of opportunity. This review is designed to help employees and their managers reflect and align on the previous year’s performance and expectations for the upcoming year.',
        sections: questionnaireAnnualReview(cycleType),
    },
    {
        id: '2',
        title: 'Objectives and Outcomes',
        description: 'Consider previously set goals, their outcomes, and start to develop a plan for moving forward. This review is designed to help employees reflect on the progress made towards their Objectives and to set expectations for new or continuing Objectives.',
        sections: questionnaireObjectives,
    },
    {
        id: '3',
        title: 'End of Probation',
        description: 'Check in with a new employee to understand and discuss how they’re adapting to their role and any support that they may need to succeed. This review is designed to be used at the end of an employee probationary period.',
        sections: questionnaireProbation(cycleType),
    },
    {
        id: '4',
        title: 'Career Growth',
        description: 'Understand your employee’s long-term career goals and aspirations. This review is designed to help ensure that employees’ personal career goals are being considered and that their strengths are being utilized.',
        sections: questionnaireCareerGrowth,
    },
];
