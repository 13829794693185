import { compact, constant, equals, find, flow, head, isObject, map, pick, pickBy, pipe, sortBy } from 'lodash/fp';
import { mapDto } from '../../../../../../types/DtoEntity';
import { fromReviewQuestionnaire } from '../../../ReviewQuestionnaireAdminPage/codec/reviewQuestionnaireDecoder';
import { getDefaultQuestionnaire } from '../defaultQuestionnaire';
export const questionnaireToTemplateSections = questionnaire => {
    return compact([
        questionnaire.graph.enabled && {
            id: 'graph',
            type: 'Graph',
            data: questionnaire.graph,
        },
        ...questionnaire.sections,
        questionnaire.secondaryOverallRatings.enabled && {
            id: 'secondaryOverallRating',
            type: 'SecondaryOverallRating',
            data: questionnaire.secondaryOverallRatings,
        },
        questionnaire.overallRatings.enabled && {
            id: 'overallRating',
            type: 'OverallRating',
            data: questionnaire.overallRatings,
        },
    ]);
};
export const templateSectionsToQuestionnaire = (cycleType, templateSections, templateId) => {
    var _a, _b, _c, _d, _e;
    const defaultQuestionnaire = getDefaultQuestionnaire();
    const graph = ((_a = find(section => section.type === 'Graph', templateSections)) === null || _a === void 0 ? void 0 : _a.data) || {};
    const overallRatings = ((_b = find(section => section.type === 'OverallRating', templateSections)) === null || _b === void 0 ? void 0 : _b.data) || {};
    const secondaryOverallRatings = ((_c = find(section => section.type === 'SecondaryOverallRating', templateSections)) === null || _c === void 0 ? void 0 : _c.data) || {};
    const sections = templateSections.filter(section => section.type !== 'Graph' && section.type !== 'OverallRating' && section.type !== 'SecondaryOverallRating');
    const noTemplateAndCalibration = !templateId && cycleType === 'CALIBRATION';
    return {
        graph: Object.assign(Object.assign(Object.assign({}, defaultQuestionnaire.graph), graph), { enabled: !!(graph === null || graph === void 0 ? void 0 : graph.enabled) }),
        overallRatings: Object.assign(Object.assign(Object.assign({}, defaultQuestionnaire.overallRatings), overallRatings), { enabled: !!((_d = overallRatings.enabled) !== null && _d !== void 0 ? _d : (noTemplateAndCalibration ? true : defaultQuestionnaire.overallRatings.enabled)) }),
        secondaryOverallRatings: Object.assign(Object.assign(Object.assign({}, defaultQuestionnaire.secondaryOverallRatings), secondaryOverallRatings), { enabled: !!((_e = secondaryOverallRatings.enabled) !== null && _e !== void 0 ? _e : (noTemplateAndCalibration ? true : defaultQuestionnaire.secondaryOverallRatings.enabled)) }),
        sections,
        templateId,
    };
};
const toSection = (id, type, data) => {
    return data.enabled
        ? [
            {
                id,
                type,
                data,
            },
        ]
        : [];
};
const toSectionWithEditModeFlag = (id, type, data) => {
    data.inEditMode = false;
    return toSection(id, type, data);
};
const toFrontendTemplate = ({ id, value: [meta, legacyReview] }) => {
    const graphSection = toSection('graph', 'Graph', legacyReview.graph);
    const overallRatingSection = toSectionWithEditModeFlag('overallRating', 'OverallRating', legacyReview.overallRatings);
    const secondaryOverallRatingSection = toSectionWithEditModeFlag('secondaryOverallRating', 'SecondaryOverallRating', legacyReview.secondaryOverallRatings);
    return {
        id,
        title: meta.name,
        description: meta.description,
        sections: [
            ...graphSection,
            ...legacyReview.sections,
            ...secondaryOverallRatingSection,
            ...overallRatingSection,
        ],
    };
};
const managerRevieweeNormalizer = questionType => managerOrRevieweeData => {
    switch (questionType) {
        case 'TEXT': {
            const toPick = managerOrRevieweeData.ratingsEnabled ? ['question', 'ratings'] : ['question'];
            return pick(toPick, managerOrRevieweeData);
        }
        case 'SCALES': {
            const questionConfig = pick(['allowComment', 'question'], managerOrRevieweeData);
            // Sometimes we duplicate scale and ratings, in which case we need to pick one.
            const scale = head(Object.values(pick(['ratings', 'scale'], managerOrRevieweeData))).map(s => {
                // Mismatch between backend and frontend
                if (isObject(s)) {
                    return s.text;
                }
                else {
                    return s;
                }
            });
            return [questionConfig, scale];
        }
        case 'SLIDER': {
            const questionConfig = pick(['allowComment', 'question'], managerOrRevieweeData);
            return [questionConfig];
        }
        case 'CHECKBOXES': {
            const questionConfig = pick(['allowComment', 'question'], managerOrRevieweeData);
            const options = head(Object.values(pick(['ratings', 'scale'], managerOrRevieweeData))).map(s => {
                // Mismatch between backend and frontend
                if (isObject(s)) {
                    return s.text;
                }
                else {
                    return s;
                }
            });
            return [questionConfig, options];
        }
    }
    return managerOrRevieweeData;
};
const sectionNormalizer = section => {
    if (section.type === 'Graph') {
        section = Object.assign(Object.assign({}, section), { questionType: 'GRAPH' });
    }
    if (section.type === 'OverallRating') {
        section = Object.assign(Object.assign({}, section), { questionType: 'OVERALL_RATING' });
    }
    switch (section.questionType) {
        case 'HEADING':
            return pick(['description', 'title'], section);
        case 'TEXT': {
            // We pick fields that are true since false is default (and implicit if field not specified)
            const questionMeta = pipe(pickBy(Boolean), pick(['confidentialTopic', 'requiredForManager', 'requiredForReviewee']))(section);
            const questionData = [section.manager, section.reviewee].map(managerRevieweeNormalizer(section.questionType));
            return [questionMeta, questionData];
        }
        case 'SCALES': {
            // We pick fields that are true since false is default (and implicit if field not specified)
            const questionMeta = pipe(pickBy(Boolean), pick(['confidentialTopic', 'requiredForManager', 'requiredForReviewee']))(section);
            const questionData = [section.manager, section.reviewee].map(managerRevieweeNormalizer(section.questionType));
            return [questionMeta, questionData];
        }
        case 'SLIDER': {
            // We pick fields that are true since false is default (and implicit if field not specified)
            const questionMeta = pipe(pickBy(Boolean), pick(['confidentialTopic', 'requiredForManager', 'requiredForReviewee', 'sliderLabels', 'sliderSteps']))(section);
            const questionData = [section.manager, section.reviewee].map(managerRevieweeNormalizer(section.questionType));
            return [questionMeta, questionData];
        }
        case 'CHECKBOXES': {
            // We pick fields that are true since false is default (and implicit if field not specified)
            const questionMeta = pipe(pickBy(Boolean), pick(['confidentialTopic', 'requiredForManager', 'requiredForReviewee']))(section);
            const questionData = [section.manager, section.reviewee].map(managerRevieweeNormalizer(section.questionType));
            return [questionMeta, questionData];
        }
        case 'DROPDOWN': {
            // Not used by templates
            return [];
        }
        case 'GRAPH': {
            return section;
        }
        case 'OVERALL_RATING': {
            return pipe(pickBy(Boolean), pick([
                'confidential',
                'enabledForManager',
                'enabledForReviewee',
                'ratings',
                'requiredForManager',
                'requiredForReviewee',
            ]))(section.data);
        }
    }
};
export function areTemplateSectionsEqual(templateSectionsA, templateSectionsB) {
    try {
        return equals(templateSectionsA.map(sectionNormalizer), templateSectionsB.map(sectionNormalizer));
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error('failed to match template due to an error', e);
        return false;
    }
}
export function getCompanyTemplates(questionnaires, defaultQuestionnaire) {
    return flow(constant(questionnaires), sortBy(x => x.value.order), map(mapDto(fromReviewQuestionnaire(defaultQuestionnaire))), map(toFrontendTemplate))();
}
