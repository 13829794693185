import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import Link from '../../../components/Link';
const SUPPORT_LINK = 'mailto:help@small-improvements.com';
const IntercomSupportLink = ({ inHeader, onClick }) => {
    const handleClick = ev => {
        ev.preventDefault();
        onClick === null || onClick === void 0 ? void 0 : onClick();
        window.Intercom('show');
    };
    return (_jsx(Link, { onClick: handleClick, className: inHeader ? '' : 'main_navigation-footer-link', href: SUPPORT_LINK, title: "Click here to get in touch with customer support!", eventTracking: {
            category: inHeader ? 'navigation header help' : 'navigation footer',
            action: 'click',
            label: 'get help as admin: intercom',
        }, children: inHeader ? 'Get Help' : 'Need Support?' }));
};
export default IntercomSupportLink;
