// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OnboardingSurveyDone-illustrationHeading-azL3a {\n  margin: 0;\n}\n.OnboardingSurveyDone-illustration-3O2AU {\n  width: 100%;\n  max-width: 500px;\n  padding: 20px;\n  aspect-ratio: 1;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 20px;\n  font-style: italic;\n}\n.OnboardingSurveyDone-illustrationCharacter-1YH1a {\n  text-align: center;\n}\n.OnboardingSurveyDone-illustrationCharacterImage-2OwR1 {\n  border-radius: 50%;\n  margin-bottom: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"illustrationHeading": "OnboardingSurveyDone-illustrationHeading-azL3a",
	"illustration": "OnboardingSurveyDone-illustration-3O2AU",
	"illustrationCharacter": "OnboardingSurveyDone-illustrationCharacter-1YH1a",
	"illustrationCharacterImage": "OnboardingSurveyDone-illustrationCharacterImage-2OwR1"
};
module.exports = exports;
