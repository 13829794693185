import { curry, getOr } from 'lodash/fp';
import { getCurrentUser } from '../CurrentUser';
export const isFeatureEnabledForUser = curry((feature, currentUser) => {
    const enabledFeatures = getOr({}, 'company.enabledFeatures', currentUser);
    // check for user specific access here too
    return !!enabledFeatures[feature];
});
export const isFeatureRestrictedForUser = curry((feature, currentUser) => {
    const enabledFeatures = getOr({}, 'company.restricted', currentUser);
    // check for user specific access here too
    return !!enabledFeatures[feature];
});
export const isFeatureEnabled = (feature) => isFeatureEnabledForUser(feature, getCurrentUser() || {});
export const isFeatureRestricted = (feature) => isFeatureRestrictedForUser(feature, getCurrentUser());
export const isFeatureAvailable = (feature) => feature ? isFeatureEnabled(feature) || !isFeatureRestricted(feature) : false;
