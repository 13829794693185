import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import styles from './style.m.less';
export function ButtonContainer({ children, className }) {
    return _jsx("div", { className: classNames(styles.container, className), children: children });
}
/**
 * DEPRECATED. Please use `ButtonContainer` for anything new.
 */
function DeprecatedButtonContainer(props) {
    const containerProps = Object.assign(Object.assign({}, props), { className: classNames('button-container', props.className) });
    return _jsx("div", Object.assign({}, containerProps));
}
DeprecatedButtonContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
export default DeprecatedButtonContainer;
