import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import Link from '../../../components/Link';
import { getCurrentUser } from '../../../services/CurrentUser';
const SUPPORT_LINK = 'mailto:support@small-improvements.com';
export default class HelpscoutSupportLink extends React.Component {
    constructor(props) {
        super(props);
        this.unmounted = false;
        this.safeSetState = (newState) => {
            if (this.unmounted) {
                return;
            }
            this.setState(newState);
        };
        this.handleClick = async (event) => {
            var _a, _b;
            (_b = (_a = this.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a);
            event.preventDefault();
            if (!window.Beacon) {
                try {
                    await this.initHelpScoutWidget();
                }
                catch (e) {
                    // eslint-disable-next-line require-atomic-updates
                    window.Beacon = undefined;
                    this.openLink();
                    this.safeSetState({ loading: false });
                    return;
                }
            }
            /* eslint-disable-next-line */
            window.Beacon('open');
        };
        this.state = {
            loading: false,
        };
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    async initHelpScoutWidget() {
        this.initReadyQueue();
        /* eslint-disable new-cap */
        this.setState({ loading: true });
        window.Beacon('once', 'open', () => this.safeSetState({ loading: false }));
        window.Beacon('init', 'd1be285c-4006-4efc-87fc-fee7738d9935');
        window.Beacon('config', {
            display: {
                style: 'manual',
                position: 'right',
            },
        });
        const { name, email, company, helpscoutSignature } = getCurrentUser();
        const userObj = {
            name,
            email,
            organisation: company.name,
            signature: helpscoutSignature,
        };
        window.Beacon('identify', userObj);
        /* eslint-enable new-cap */
        await this.addBeaconScript();
    }
    initReadyQueue() {
        window.Beacon = (method, options, data) => {
            if (window.Beacon.readyQueue === undefined) {
                window.Beacon.readyQueue = [];
            }
            window.Beacon.readyQueue.push({ method: method, options: options, data: data });
        };
    }
    addBeaconScript() {
        return new Promise((resolve, reject) => {
            const beaconScript = document.createElement('script');
            beaconScript.type = 'text/javascript';
            beaconScript.async = true;
            beaconScript.defer = true;
            beaconScript.src = 'https://beacon-v2.helpscout.net';
            beaconScript.onload = () => resolve();
            beaconScript.onerror = () => reject();
            document.getElementsByTagName('head')[0].appendChild(beaconScript);
        });
    }
    openLink() {
        const anchor = document.createElement('a');
        anchor.href = SUPPORT_LINK;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
    render() {
        const { inHeader } = this.props;
        return this.state.loading ? (_jsx("span", { className: inHeader ? 'link-style' : 'main_navigation-footer-link', children: "Loading..." })) : (_jsx(Link, { onClick: this.handleClick, className: inHeader ? '' : 'main_navigation-footer-link', href: SUPPORT_LINK, title: "Click here to get in touch with customer support!", eventTracking: {
                category: inHeader ? 'navigation header help' : 'navigation footer',
                action: 'click',
                label: 'get help as admin: helpscout',
            }, children: inHeader ? 'Get Help' : 'Need Support?' }));
    }
}
