import history from '../../routing/history';
import { getCurrentUser } from './CurrentUser';
import { isFeatureEnabled } from './Features';
import { isAnyAdmin } from './User';
let initPromise;
export const shouldEnableIntercom = (currentUser = getCurrentUser()) => isFeatureEnabled('FEATURE_intercom') && isAnyAdmin(currentUser);
function load() {
    if (!initPromise) {
        initPromise = new Promise((resolve, reject) => {
            // Provided by Intercom
            (function () {
                const w = window;
                const ic = w.Intercom;
                if (typeof ic === 'function') {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                }
                else {
                    const d = document;
                    const i = function (...args) {
                        i.c(args);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    const l = function () {
                        const s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/qbukee5s';
                        const x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                        s.onload = () => {
                            resolve(w.Intercom);
                        };
                        s.onerror = () => {
                            reject();
                        };
                    };
                    if (document.readyState === 'complete') {
                        l();
                    }
                    else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    }
                    else {
                        w.addEventListener('load', l, false);
                    }
                }
            })();
        });
    }
    return initPromise;
}
export function initIntercom(currentUser = getCurrentUser()) {
    if (shouldEnableIntercom()) {
        load().then((intercom) => {
            intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'qbukee5s',
                name: currentUser.name,
                email: currentUser.email,
                user_hash: currentUser.intercomSignature,
                created_at: Math.round(currentUser.dateCreated / 1000),
                hide_default_launcher: true,
            });
            if (history) {
                history.listen(() => intercom('update'));
            }
        }, () => { });
    }
}
