import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import api from '../../../../api';
import Button from '../../../components/Button';
import DeprecatedButtonContainer from '../../../components/ButtonContainer';
import SaveButton, { ENABLED, SAVED, SAVING } from '../../../components/SaveButton';
import { redirect } from '../../../services/Router';
import AdvancedSettings from './inputs/AdvancedSettings';
import CompanySettings from './inputs/CompanySettings';
import AdminDateTime from './inputs/DateTime';
import DefaultObjectiveCycleSettings from './inputs/DefaultObjectiveCycleSettings';
import MoreAdvancedSettings from './inputs/MoreAdvancedSettings';
import { create, getButtonState, getCompanySettingsAdvanced, getDateTimeSettings, setButtonState, } from './model';
import styles from './style.m.less';
const AdminCompanySettings = ({ companySettingsAdvanced, dateTimeSettings }) => {
    const [model, setModel] = useState(create(companySettingsAdvanced, dateTimeSettings, ENABLED));
    // Store initial values for comparison
    const initialCompanySettings = companySettingsAdvanced;
    const initialDateTimeSettings = dateTimeSettings;
    const handleSubmit = async (actionModel, updateModel) => {
        updateModel(setButtonState(SAVING, actionModel));
        const companySettingsChanged = JSON.stringify(getCompanySettingsAdvanced(actionModel)) !== JSON.stringify(initialCompanySettings);
        const dateTimeSettingsChanged = JSON.stringify(getDateTimeSettings(actionModel)) !== JSON.stringify(initialDateTimeSettings);
        if (companySettingsChanged) {
            await api.companySettingsAdvanced.setCompanySettingsAdvanced(getCompanySettingsAdvanced(actionModel));
        }
        if (dateTimeSettingsChanged) {
            await api.companyDateTimeSettings.setDateTimeSettings(getDateTimeSettings(actionModel));
        }
        updateModel(setButtonState(SAVED, model));
        setTimeout(() => updateModel(setButtonState(ENABLED, model)), 4000);
    };
    const Actions = ({ model: actionModel, setModel: updateModel }) => {
        const saveProps = {
            onClick: () => handleSubmit(actionModel, updateModel),
            state: getButtonState(actionModel),
        };
        const cancelProps = {
            onClick: () => redirect('/app/admin/settings'),
            children: 'Cancel',
        };
        return (_jsxs(DeprecatedButtonContainer, { className: styles.buttons, children: [_jsx(Button, Object.assign({}, cancelProps)), _jsx(SaveButton, Object.assign({}, saveProps))] }));
    };
    return (_jsxs("div", { className: "page_static", children: [getButtonState(model) === 'SAVED' && _jsx("p", { className: "boxGentleHint", children: "Your changes have been saved." }), _jsxs("div", { className: "preventLeaving", children: [_jsx(CompanySettings, { model: model, setModel: setModel }), _jsx(AdminDateTime, { model: model, setModel: setModel }), _jsx(AdvancedSettings, { model: model, setModel: setModel }), _jsx(Actions, { model: model, setModel: setModel }), _jsx(DefaultObjectiveCycleSettings, {}), _jsx(MoreAdvancedSettings, {})] })] }));
};
export default AdminCompanySettings;
