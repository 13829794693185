import api from '../../../../../api';
import { ADMIN_SAMPLE_CONTENT_PROGRESS } from '../../../../../routes';
import { goToUrl } from '../../../../services/Router';
import { interpolateAppUrl } from '../../../../services/Url';
const getTimestampWithAdditionalThreeMinutes = () => {
    const ts = new Date();
    ts.setMinutes(ts.getMinutes() + 3);
    return ts;
};
function redirectToProgressPage(mode) {
    return goToUrl(interpolateAppUrl(ADMIN_SAMPLE_CONTENT_PROGRESS)({ mode }), {
        resetProcessEndTimestamp: getTimestampWithAdditionalThreeMinutes(),
    });
}
export const deleteSampleContent = () => {
    const mode = 'deleteSampleContent';
    return api.sampleContent.deleteSampleContent().then(() => redirectToProgressPage(mode));
};
export const resetSampleContent = (model) => {
    const mode = 'reset';
    return api.sampleContent.resetTrial(model).then(() => redirectToProgressPage(mode));
};
export const deleteAllContent = (model) => {
    const mode = 'deleteAll';
    return api.sampleContent.deleteAllContentIncludingCycles(model).then(() => redirectToProgressPage(mode));
};
