import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useState } from 'react';
import Button from '../../../../components/Button';
import { isHr } from '../../../../services/User';
import { GetADemoModal } from './GetADemoModal';
const GetADemoButton = ({ user }) => {
    const [isOpen, setisOpen] = useState(false);
    if (user.company.creator === user.id && isHr(user) && !user.company.payment.paidFrom) {
        return (_jsxs(_Fragment, { children: [_jsx(GetADemoModal, { isOpen: isOpen, setisOpen: setisOpen, userServer: user.company.server }), _jsx("div", { className: "app-header-button-content", children: _jsx(Button, { kind: "PRIMARY", isSmall: true, onClick: () => setisOpen(true), eventTracking: { category: 'navigation header', action: 'request demo' }, children: user.company.server !== 'prod-eu' ? _jsx("span", { children: "Book a Consultation" }) : _jsx("span", { children: "Get a Demo" }) }) })] }));
    }
    return null;
};
export default GetADemoButton;
